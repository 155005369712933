import { Pagination } from 'src/app/model/pagination.model';
import { File } from '../../file/file';
import { Form } from '../../forms/state';

export interface Submission {
  forms: Form[];
  formsPrefill?: { submission: Submission, form: Form }[];

  // integration
  ownerOrgId: string;
  ownerEmail: string;
  recipientOrgId: string;
  recipientOrgName: string;
  recipientName: string;
  recipients: string[];
  title: string;
  submissionsCycleId: string;
  submissionCycleEnd: Date | string;
  id: string;
  submissionsId?: string;
  createdOn: string;
  modifiedOn: Date | string;
  percentageCompleted: number;
  formName: string;


  assetUrl: string[];
  fileName: string[];
  submissionsData: SubmissionData;
  data?: SubmissionData;

  status: 'not_started' | 'in_progress' | 'completed' | 'pending'; // Assuming these are the possible statuses
  submissionsCycleData: Form;
  filesUploaded: File[];

  formId: string;
  ownerOrgName: string;
}


export interface SubmissionsResponse {
  submissionsCycleData: SubmissionCycle;
  submissionsData: Submission[];
}
export interface SubmissionData {
  id: string;
  createdOn: string;
  size: number;
  data: any; // You can specify the type of data here
}

export interface SubmissionsResponse extends Pagination {
  items: Submission[];
  total: number;
  page: number;
  size: number;
  pages: number;
}


export interface FormSubmission {
  id: string;
  forms: Form[];
  percentageCompleted?: number;
  status: string | undefined | null;
  deadline: Date | string;
  recepientName: string;
}

export interface SubmissionsCycleResponse {
  recipientOrgId: string;
  assetUrl: string[]
  fileName: string[]
  title: string;
  submissionsCycleId: string;
}

export interface SubmissionsCycleStatisticsResponse {
  submissionsCycleName: string;
  submissionsCycleId: string,
  submissionsCycleEnd: Date;
  submissionsSummary: SubmissionsCycleSummary;
}

export interface SubmissionCycle {
  submissionsCycleEnd: string;
  submissionsCycleId: string;
  submissionsCycleName: string;
  formData: any;
}

export interface SubmissionsCycleSummary {
  total: number;
  submitted: number;
  inProgress: number;
  notStarted: number;
}

export function createSubmission(params?: Partial<Submission>) {
  return {
  } as Submission;
}

