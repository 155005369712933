export enum FormType {
  selectboxes = 'selectboxes',
  radio = 'radio',
  select = 'select',
  datagrid = 'datagrid'
}

export const PDF_TEMPLATE_STYLE_CLASS = `
          
  .pdf-preview-section {
    font-size: .625rem;
    font-family: Nunito Sans;
  }

   /* Styles for the question content */
   .pdf-form-question  {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 4px;     
  }

  .pdf-form-question h3 {  
        font-size: 1rem;
        font-weight: 700;
        color: #417E71;
      }

    /* Styles for table */
    .pdf-table {
      width: 100%;
      overflow-x: auto;
      display: block;
  }

   /* Styles for table row */
   .pdf-table-row {
    display: flex;
    flex-wrap: wrap;
    }

  .pdf-table-cell {
    flex: 1;
    padding: 8px;
    min-width: 120px;
    text-align: left;
    border: none;
    word-wrap: break-word;
}



/* Styles for pdf preview template section */
.pdf-pdf-preview-section {
  page-break-inside: avoid;
}


/* Styles for the question content */
.pdf-form-question {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 4px;
}

/* Styles for the response container */
.pdf-response-container {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 4px;
}

/* Styles for the response label */
.pdf-response-label {
  margin-bottom: 2px;
  opacity: 0.6;

  font-size: .625rem;;
  font-weight: 600;
  color: #767680;
}

/* Styles for each item in selectboxes */
.pdf-response-item {
  display: flex;
  gap: 2px;
}

/* Styles for table */
.pdf-table {
  width: 100%;
  overflow-x: auto;
  display: block;
}

/* Styles for table row */
.pdf-table-row {
  display: flex;
  flex-wrap: wrap;
}

/* Styles for table header */
.pdf-header {
  color: #000000;
  font-weight: 600;
  background: #E1FBF0;
}

/* Styles for table header */
.pdf-header {
  color: #000000;
  font-weight: 600;
  background: #E1FBF0;
}

.pdf-header .pdf-table-cell {
  border-top: .73px solid #C6C6D0;
  border-bottom: .73px solid #C6C6D0;
}

/* Styles for table cells */
.pdf-table-cell {
  flex: 1;
  padding: 8px;
  min-width: 120px;
  text-align: left;
  border: none;
  word-wrap: break-word;
}

/* Default response styling */
.pdf-default-response {
  word-wrap: break-word;
}

.pdf-start {
  margin-bottom: 2rem;
}

.pdf-preview-header {
  padding: 4px;
}

.pdf-start-info label {
  font-weight: 700;
  font-size: .75rem;
}

.pdf-start-info span {
  font-weight: 400;
  font-size: .75rem;
}

.pdf-start-info title {
  font-weight: 700;
  font-size: 1rem;
}

.pdf-top-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  align-items: center;
}

.pdf-submission-date {
  font-size: .75rem;
  font-weight: 600;
}

::ng-deep .pdf-start-content > *{
  font-size: 0.75rem !important;
  font-weight: 600 !important;
}

.pdf-start-content  {
  width: 80%;
}

.pdf-start-title {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

#img-top-header {
  width: 90px;
  height: 30px;
}

.pdf-start-info-container {
  margin-bottom: 2rem;
}

                `;
